<template>
  <LayoutSuperadmin>
    <template v-slot:title>Categories</template>
    <template v-slot:topbar>
      <AppBtn
        class="text-uppercase ml-3 d-none d-md-inline-block"
        @click="openAddModal"
      >
        Add Category
      </AppBtn>
    </template>
    <div class="p-2 p-md-5 bg-gray-light-4 h-100 w-100 overflow-auto">
      <div class="category-list m-auto d-block">
        <AppBtn
          v-if="searchFilter"
          text
          color="info w-100"
          @click="searchFilter = ''"
        >
          Clear Search query...
        </AppBtn>
        <AppCard class="p-3">
          <div class="d-flex justify-content-between">
            <h3>Manage Category</h3>
            <!-- Disabled. To be removed if not mentioned to be added -->
            <AppInput
              v-if="false"
              v-model="searchFilter"
              appendIcon="search"
              placeholder="Search"
            ></AppInput>

            <AppBtn
              class="text-uppercase ml-3 d-md-none d-inline-block"
              @click="openAddModal"
            >
              Add Category
            </AppBtn>
          </div>
          <SortBtn v-if="false" :items="sortOption"></SortBtn>

          <!-- Categories -->
          <div class="categories mt-2 mt-md-5">
            <div class="row">
              <div class="col">
                <div
                  v-for="(category, index) in shownItems.items.slice(0, 10)"
                  :key="`cat-col-1-${index}`"
                  class="
                    categories-item
                    hoverable
                    mb-2
                    p-2
                    d-flex
                    justify-content-between
                  "
                  @click="openEditModal(category)"
                >
                  <span class="text-truncate">{{ category.category }}</span>
                  <AppIcon
                    name="edit"
                    class="categories-item-icon mr-md-4 text-info"
                  ></AppIcon>
                </div>
              </div>
              <!-- Second column -->
              <div v-if="shownItems.items.length > 10" class="col">
                <div
                  v-for="(category, index) in shownItems.items.slice(10, 20)"
                  :key="`cat-col-2-${index}`"
                  class="
                    categories-item
                    hoverable
                    mb-2
                    p-2
                    d-flex
                    justify-content-between
                  "
                  @click="openEditModal(category)"
                >
                  <span class="text-truncate">{{ category.category }}</span>
                  <AppIcon
                    name="edit"
                    class="categories-item-icon mr-md-4 text-info"
                  ></AppIcon>
                </div>
              </div>
            </div>
            <AppPagination
              :currentPage="shownItems.current_page"
              :itemsPerPage="numberOfItems"
              :total="shownItems.total"
              @pageChange="changePange"
            ></AppPagination>
          </div>
        </AppCard>

        <!-- Add Modal -->
        <AppModal v-model="categoryAdd.modal" width="450px">
          <AppCard>
            <AppInputForm
              class="p-3"
              v-model="categoryAdd.formValid"
              v-slot="{ validate }"
              @validate="addCategory"
            >
              <div class="d-flex align-items-center justify-content-between">
                <h4>ADD NEW CATEGORY</h4>
                <AppIcon
                  hoverable
                  name="close"
                  @click="categoryAdd.modal = false"
                ></AppIcon>
              </div>

              <div class="mt-3">
                <AppInput
                  v-model="categoryAdd.form.category"
                  label="Category Name"
                  class="mb-3"
                  :validate="validate"
                  :validations="[
                    validation.required(),
                    validation.maxLength(100),
                  ]"
                ></AppInput>
              </div>

              <div
                class="
                  d-flex
                  flex-column flex-md-row
                  justify-content-center justify-content-md-end
                "
              >
                <AppBtn
                  outline
                  :loading="loading"
                  @click="categoryAdd.modal = false"
                >
                  CANCEL
                </AppBtn>
                <AppBtn
                  type="subumit"
                  class="mt-2 mt-md-0 ml-md-2"
                  :loading="loading"
                >
                  ADD CATEGORY
                </AppBtn>
              </div>
            </AppInputForm>
          </AppCard>
        </AppModal>
        <!-- Confirm Add Modal -->
        <ActionModal v-model="categoryAdd.confirm" @confirm="addCategory">
          <template v-slot:title> ADD CATEGORY </template>
          <template v-slot:description>
            Are you sure you want add this category?
          </template>
        </ActionModal>
        <!-- Success Add Modal -->
        <ActionModal v-model="categoryEdit.success">
          <template v-slot:title> NEW CATEGORY SAVED </template>
        </ActionModal>

        <!-- Edit Modal -->
        <AppModal v-model="categoryEdit.modal" width="450px">
          <AppCard class="p-3">
            <div class="d-flex align-items-center justify-content-between">
              <h4>EDIT CATEGORY</h4>
              <AppIcon
                hoverable
                name="close"
                @click="categoryEdit.modal = false"
              ></AppIcon>
            </div>

            <div class="mt-3">
              <AppInput
                v-model="categoryEdit.form.category"
                label="Category Name"
              ></AppInput>
            </div>

            <div
              class="
                d-flex
                flex-column flex-md-row
                justify-content-center justify-content-md-end
              "
            >
              <AppBtn
                outline
                :loading="loading"
                @click="categoryDelete.confirm = true"
              >
                DELETE
              </AppBtn>
              <AppBtn
                class="mt-2 mt-md-0 ml-md-2"
                :loading="loading"
                @click="categoryEdit.confirm = true"
              >
                SAVE
              </AppBtn>
            </div>
            <p class="m-0 mt-1 text-danger text-md-right">
              {{ categoryEdit.error || categoryDelete.error }}
            </p>
          </AppCard>
        </AppModal>

        <!-- Confirm Edit Modal -->
        <ActionModal v-model="categoryEdit.confirm" @confirm="editCategory">
          <template v-slot:title> EDIT CATEGORY </template>
          <template v-slot:description>
            Are you sure you want change this category?
          </template>
        </ActionModal>

        <!-- Success Edit Modal -->
        <SuccessModal v-model="categoryEdit.success">
          <template v-slot:title> CATEGORY SAVED </template>
        </SuccessModal>

        <!-- Confirm Delete Modal -->
        <ActionModal
          v-model="categoryDelete.confirm"
          state="delete"
          @confirm="deleteCategory"
        >
          <template v-slot:title> DELETE CATEGORY </template>
          <template v-slot:description>
            Are you sure you want to delete this category?
          </template>
        </ActionModal>

        <!-- Success Delete Modal -->
        <SuccessModal v-model="categoryDelete.success">
          <template v-slot:title> CATEGORY DELETED </template>
        </SuccessModal>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import Products from '@/shared/api/Products';
import AppCard from '@/shared/elements/AppCard.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import SortBtn from '@/components/Partial/SortBtn.vue';
import AppPagination from '@/shared/elements/AppPagination.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import AppModal from '@/shared/elements/AppModal.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import LayoutSuperadmin from '../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import SuccessModal from '../../../components/Partial/Modals/SuccessModal.vue';
import AppInputForm from '../../../shared/elements/AppInputForm.vue';
import validation from '@/shared/utils/validation';
import { topbarSearchEvent } from '@/shared/events';

export default {
  components: {
    AppCard,
    AppInput,
    SortBtn,
    AppPagination,
    AppIcon,
    ActionModal,
    SuccessModal,
    AppModal,
    AppBtn,
    LayoutSuperadmin,
    AppInputForm,
  },
  name: 'SuperAdminCategoryList',

  mixins: [_appApiHelper],

  data() {
    return {
      validation,
      searchFilter: this.$route?.query.search || '',
      sortOption: [
        {
          value: 'recent',
          text: 'Recently Added',
        },
      ],
      numberOfItems: 20,
      current_page: 1,
      categories: [],
      category_id: null,
      categoryAdd: {
        modal: false,
        confirm: false,
        success: false,
        error: null,
        formValid: false,
        form: {
          category: '',
        },
      },
      categoryEdit: {
        modal: false,
        confirm: false,
        success: false,
        error: null,
        form: {
          category: '',
        },
      },
      categoryDelete: {
        confirm: false,
        success: false,
        error: null,
      },
    };
  },

  computed: {
    shownItems() {
      const current_page = this.current_page;
      const numberOfItems = this.numberOfItems;
      const start = numberOfItems * (current_page - 1);
      const end = numberOfItems * current_page;
      const filteredItems = this.categories.filter((item) =>
        item.category.includes(this.searchFilter)
      );
      const items = filteredItems.slice(start, end);
      return {
        items,
        current_page,
        total: this.searchFilter
          ? filteredItems.length
          : this.categories.length,
      };
    },
  },

  watch: {
    $route: {
      handler() {
        this.searchFilter = this.$route?.query?.search;
        // this.fetchCategories();
      },
      deep: true,
    },
  },

  methods: {
    async fetchCategories() {
      const response = await Products.getCategories();
      this.categories = response.data;
    },
    changePange(page) {
      this.current_page = page;
    },
    openAddModal() {
      this.categoryAdd.modal = true;
      this.categoryAdd.error = null;
      this.categoryEdit.error = null;
      this.categoryDelete.error = null;
    },
    openEditModal(category) {
      this.categoryAdd.error = null;
      this.categoryEdit.error = null;
      this.categoryDelete.error = null;
      this.category_id = category.id;
      this.categoryEdit.form.category = category.category;
      this.categoryEdit.modal = true;
    },
    async addCategory() {
      if (!this.categoryAdd.formValid) {
        return;
      } else if (!this.categoryAdd.confirm) {
        return (this.categoryAdd.confirm = true);
      }

      try {
        const { category } = this.categoryAdd.form;
        const response = await Products.postCategory(category);
        if (response.status === 200) {
          this.categoryAdd.success = true;
          this.categoryAdd.modal = false;
          this.categoryAdd.form.category = '';
          this.fetchCategories();
        } else {
          this.categoryAdd.error = this.error;
        }
      } catch {
        this.categoryDelete.error = this.error;
      }
    },
    async editCategory() {
      try {
        const id = this.category_id;
        const form = this.categoryEdit.form;
        const response = await Products.editCategory(id, form);

        if (response.status === 200) {
          this.categoryEdit.success = true;
          this.categoryEdit.modal = false;
          this.fetchCategories();
        } else {
          this.categoryEdit.error = this.error;
        }
      } catch {
        this.categoryDelete.error = this.error;
      }
    },
    async deleteCategory() {
      try {
        const id = this.category_id;
        const response = await Products.deleteCategory(id);

        if (response.status === 200) {
          this.categoryDelete.success = true;
          this.categoryEdit.modal = false;
          this.fetchCategories();
        } else {
          this.categoryDelete.error = this.error;
        }
      } catch {
        this.categoryDelete.error = this.error;
      }
    },
    listenOnSearchCategory(on = true) {
      if (!on) {
        topbarSearchEvent.off();
      } else {
        topbarSearchEvent.on((val) => {
          this.searchFilter = val;
        });
      }
    },
  },

  mounted() {
    this.fetchCategories();
    // this.listenOnSearchCategory();
  },

  beforeDestroy() {
    // this.listenOnSearchCategory(false);
  },
};
</script>

<style lang="scss" scoped>
.category-list {
  max-width: 1200px;
}
.categories {
  &-item {
    &-icon {
      opacity: 0;
    }
    &:hover {
      .categories-item-icon {
        opacity: 1;
      }
    }
  }
}
</style>
