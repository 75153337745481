<template>
  <form
    ref="appForm"
    :class="{ 'app-form-dark': dark }"
    @submit.prevent="validateInputs"
    enctype="multipart/form-data"
  >
    <!-- use this validate on Input component's validate prop to activate their own validator  -->
    <slot :validate="validate" :errors="errors"></slot>
  </form>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
export default {
  name: 'AppInputForm',

  mixins: [_appDefaultInput],

  props: {
    dark: { type: Boolean, default: false },
  },

  data() {
    return {
      validate: false,
      errors: [],
    };
  },
  watch: {
    value() {
      this.$emit('validate');
    },
  },
  methods: {
    validateInputs() {
      // reinitialize validate instance
      this.validate = `validate-intance-${Math.random()}`;
      // validates all input components after triggerring
      // validation via this.validate instance
      this.$nextTick(() => {
        const form = [
          ...this.$refs.appForm.getElementsByClassName('app-form-validation'),
        ];

        let testsPass = true;
        let errors = [];
        form.forEach((errorValidationInput) => {
          if (errorValidationInput.value !== 'true') {
            testsPass = false;
            errors.push(errorValidationInput.value);
          }
        });

        if (this.innerVal === testsPass) this.$emit('validate');
        this.innerVal = testsPass;
        this.errors = errors;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
