<template>
  <AppDropdown :items="items" @itemClick="(sortItem) => (innerVal = sortItem)">
    <template v-slot:dropdown-button>
      <AppBtn color="gray-light-3" class="p-1">
        <div class="d-flex align-items-center text-body-3">
          <AppIcon name="sort_descending"></AppIcon>
          <span class="ml-2 d-none d-md-block">Sort By:</span>
          <span class="ml-2 font-weight-normal">{{ innerVal.text }}</span>
        </div>
      </AppBtn>
    </template>
  </AppDropdown>
</template>

<script>
import AppDropdown from '@/shared/elements/AppDropdown.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';

export default {
  name: 'SortBtn',

  components: { AppDropdown, AppBtn, AppIcon },

  mixins: [_appDefaultInput],

  props: {
    items: { type: Array, default: () => [] },
  },

  data() {
    return {
      innerVal: this.items[0],
    };
  },
};
</script>

<style lang="scss" scoped></style>
