/**

 * ==============================================================

 * COMMON VALIDATION CASE

 * ==============================================================

 */

/**

 * Validates that field is required

 *

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function required(name = '') {
  const required = (value, fieldName = name) => {
    if (fieldName) {
      fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    }
    const errorMessage = `${fieldName || 'This'} field shouldn't be empty.`;

    if (!value) {
      return errorMessage;
    } else if (typeof value === 'object') {
      return Object.keys(value).length > 0 || errorMessage;
    } else if (typeof value === 'number') {
      return value >= 0 || errorMessage;
    } else if (Array.isArray(value)) {
      return value.length > 0 || errorMessage;
    } else {
      return !!value || errorMessage;
    }
  };

  return required;
}

/**

 * Validates that field is required if condition is true

 *

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function requiredIf(condition, name = '') {
  const requiredIf = (value, fieldName = name) => {
    const errorMessage = `${fieldName || 'This'} field shouldn't be empty.`;

    if (!condition) {
      return true;
    }

    if (!value) {
      return errorMessage;
    } else if (typeof value === 'object') {
      return Object.keys(value).length > 0 || errorMessage;
    } else if (typeof value === 'number') {
      return value >= 0 || errorMessage;
    } else if (Array.isArray(value)) {
      return value.length > 0 || errorMessage;
    } else {
      return !!value || errorMessage;
    }
  };

  return requiredIf;
}

/**

 * Validates that field is email

 *

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function email(name = '', optional = false) {
  const email = (value, fieldName = name) => {
    return (
      /\S+@\S+\.\S+/.test(value) || optional ||
      `${fieldName || 'This'} is not a valid email`
    );
  };

  return email;
}

export function domain(name = '', optional = false) {
  const domain = (value, fieldName = name) => {
    if (!value && optional) {
      return true;
    }

    return (
      /\S+\.\S+/.test(value) || `${fieldName || 'This'} is not a valid domain`
    );
  };

  return domain;
}

/**

 * Validates if field should be number is atleast a certain number minimum

 *

 * @param min Minimum number

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function min(min, name = '') {
  return (value, fieldName = name) => {
    return (
      parseFloat(value) >= min ||
      `${fieldName || 'This'} field must be equal or greater than ${min}.`
    );
  };
}

/**

 * Validates if field should be number not exceed the given maximum number

 *

 * @param max Maximum number

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function max(max, name = '') {
  return (value, fieldName = name) => {
    return (
      parseFloat(value) <= max ||
      `${fieldName || 'This'} field must be equal or less than ${max}.`
    );
  };
}

/**

 * Validates if field should be atleast a certain number of character long

 *

 * @param count Number of minimum character

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function minLength(count, name = '') {
  return (value, fieldName = name) => {
    return (
      (value && value.length >= count) ||
      `${fieldName || 'This'} field must be atleast ${count} characters long.`
    );
  };
}

/**

 * Validates if field shouldn't exceed a certain number of character long

 *

 * @param count Number of maximum character

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function maxLength(count, name = '') {
  return (value, fieldName = name) => {
    return (
      (value && value.length < count) ||
      `${fieldName || 'This'} field must not exceed ${count} characters long.`
    );
  };
}

/**

 * ==============================================================

 * OPTIONAL VALIDATION CASES

 * ==============================================================

 */

/**

 * Validates if field should only be a number type

 *

 * @param name name of the field

 * @returns boolean: true | string: error message

 */

export function numberOnly(name = '') {
  return (value, fieldName = name) => {
    return (
      /^\d+$/.test(value) || `${fieldName || 'This'} field must be a number`
    );
  };
}

/**

 * Validates if field should only contain alphabet letters

 *

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function alphaOnly(name = '') {
  return (value, fieldName = name) => {
    return (
      /^([A-Za-z]*)$/.test(value) ||
      `${fieldName || 'This'} field should only contain alphabet letters`
    );
  };
}

/**

 * Validates if field shouldn't have any number

 *

 * @param name name of the field

 * @returns boolean: true | string: error message

 */

export function noNumber(name = '') {
  return (value, fieldName = name) => {
    return (
      /^([^0-9]*)$/.test(value) ||
      `${fieldName || 'This'} field shouldn't have any number character`
    );
  };
}

/**

 * ==============================================================

 * SPECIAL RULE NAMING FUNCTION

 * ==============================================================

 */

// export function nameValidations(name, validations) {

//   return validations.map(validation => )

// }

/**

 * Validates that field is required

 *

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function matchString(stringMatch, stringFieldName = '', name = '') {
  return (value, fieldName = name) => {
    return (
      value === stringMatch ||
      `${fieldName || 'This'} field should match ${
        stringFieldName || 'The string'
      }`
    );
  };
}

/**

 * Validates that field is not one of these inputs

 *

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function doesntContain(list, name = '') {
  return (value, fieldName = name) => {
    return !list.includes(value) || `${fieldName || 'This'} field is required.`;
  };
}

/**

 * Validates that field is a valid US phone number

 * Valid formats:

 *  +1 123 4567890

 *  +11234567890

 *  +1(123)4567890

 *  +1(123)456-7890

 *  +1 (123) 456-7890

 *  +1 (123)456 7890

 *  +1 123 456-7890

 *  +1 123 456 7890

 *  +1 123-456-7890

 *  123-456-7890

 *  123 456-7890

 *  123 456 7890

 *  123 4567890

 *  1234567890

 *

 * @see https://stackoverflow.com/questions/4338267/validate-phone-numberwith-javascript

 *

 * @param name Name of the field

 * @returns boolean: true | string: error message

 */

export function usPhoneNum(name = '') {
  return (value, fieldName = name) => {
    return (
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value) ||
      `${fieldName || 'This'} field is not a valid phone number. Must start with + and contain 10 digits`
    );
  };
}

/**

 * Validates if field is the same as another field

 *

 * @param name Name of the field

 * @param confirm_name Name of the field to confirm

 * @returns boolean: true | string: error message

 */

export function confirmed(name = '', confirmValue = '', confirmName = '') {
  return (value, fieldName = name) => {
    return (
      (value && value === confirmValue) ||
      `${fieldName || 'This'} field must match ${confirmName || 'the previous field'}.`
    );
  };
}

export default {
  required,
  email,
  min,
  max,
  minLength,
  maxLength,
  numberOnly,
  alphaOnly,
  noNumber,
  matchString,
  usPhoneNum,
  requiredIf,
  confirmed,
  domain,
};
